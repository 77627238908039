import logo from '@assets/logos/logo.svg';
import { Profile } from '@modules/profile/model';
import React, { FC, ReactNode, useMemo } from 'react';
import { Link } from 'react-router-dom';
import * as Styled from './Nav.styles';
import { ActionIcon, Anchor, Avatar, Navbar, Text } from '@mantine/core';
import { IconChartBar, IconDroplet, IconFileImport, IconLogout, IconUsers } from '@tabler/icons-react';
import { renderNullable } from '@shared/utils/render';
import { UserUtils } from '@modules/users/utils';
import { Report } from '@modules/reports/model';
import { pipe, Predicate, ReadonlyArray, Option } from 'effect';
import { ProfilePredicates } from '@modules/profile/predicates';

interface NavEntry {
  title: string;
  to: string;
  icon: ReactNode;
  canAccess: Predicate.Predicate<Profile>;
}

interface NavGroup {
  title?: string;
  entries: Array<NavEntry>;
}

const entries: Array<NavGroup> = [
  {
    entries: [
      {
        title: 'Rapports Eau',
        to: `/reports/${Report.Type.Water}`,
        icon: <IconDroplet />,
        canAccess: ProfilePredicates.anyRole,
      },
      {
        title: 'Rapports Intrant',
        to: `/reports/${Report.Type.Input}`,
        icon: <IconFileImport />,
        canAccess: ProfilePredicates.anyRole,
      },
      {
        title: "Suivi d'activité",
        to: `/reports/${Report.Type.GolfActivity}`,
        icon: <IconChartBar />,
        canAccess: ProfilePredicates.isAdmin,
      },
    ],
  },
  {
    title: 'Administration',
    entries: [{ title: 'Utilisateurs', to: '/users', icon: <IconUsers />, canAccess: ProfilePredicates.isAdmin }],
  },
];

interface NavProps {
  profile: Profile;
}

const Nav: FC<NavProps> = ({ profile }) => {
  const filteredEntries = useMemo(
    () =>
      pipe(
        entries,
        ReadonlyArray.filterMap(group => {
          const groupEntries = pipe(
            group.entries,
            ReadonlyArray.filter(entry => entry.canAccess(profile)),
          );

          return pipe(
            groupEntries,
            ReadonlyArray.match({
              onNonEmpty: entries => Option.some({ ...group, entries }),
              onEmpty: Option.none,
            }),
          );
        }),
      ),
    [profile],
  );

  return (
    <>
      <Navbar width={{ base: 270 }}>
        <Navbar.Section grow>
          <Styled.NavContent>
            <Styled.NavHeader>
              <Styled.NavHeaderLogo to="/">
                <img src={logo} alt="logo" width={100} height={66} />
              </Styled.NavHeaderLogo>
            </Styled.NavHeader>

            {filteredEntries.map((group, i) => (
              <Styled.NavGroup key={i}>
                {renderNullable(group.title, {
                  onSome: title => (
                    <Text fw={600} px={10} mb={10}>
                      {title}
                    </Text>
                  ),
                })}

                <Styled.NavEntries>
                  {group.entries.map((entry, j) => (
                    <li key={j}>
                      <Styled.NavItem to={entry.to}>
                        <Styled.NavItemIcon>{entry.icon}</Styled.NavItemIcon>

                        <Text size={14}>{entry.title}</Text>
                      </Styled.NavItem>
                    </li>
                  ))}
                </Styled.NavEntries>
              </Styled.NavGroup>
            ))}
          </Styled.NavContent>
        </Navbar.Section>

        <Navbar.Section>
          <Styled.NavFooter>
            <Avatar size="sm" radius="xl" color="dark" variant="filled">
              {UserUtils.getUserInitial(profile.email, profile.firstName, profile.lastName)}
            </Avatar>

            <Anchor component={Link} to="/profile" fw={600} color="dark" fz="sm">
              {UserUtils.getUserName(profile.email, profile.firstName, profile.lastName)}
            </Anchor>

            <ActionIcon component={Link} to="/logout" size="sm" color="green">
              <IconLogout size="14" />
            </ActionIcon>
          </Styled.NavFooter>
        </Navbar.Section>
      </Navbar>
    </>
  );
};

export default Nav;
