import React, { FC } from 'react';
import AuthLayout from '@modules/auth/components/layout/AuthLayout';
import ForgotPasswordForm from '@modules/passwords/components/forgot/ForgotPasswordForm';
import Seo from '@shared/modules/seo/Seo';
import { defineRoute, redirect } from '@core/router';
import { defineAction, useAction } from '@core/router/action';
import { Passwords } from '@modules/passwords/model';
import { PasswordService } from '@modules/passwords/service';
import { Effect, pipe, Function } from 'effect';

const actions = {
  forgotPassword: defineAction({
    type: 'forgot',
    payload: Passwords.ForgotParams,
    handler: ({ payload }) =>
      pipe(
        PasswordService.forgotPassword(payload),
        Effect.tap(() => redirect('/login')),
      ),
    flashOptions: {
      error: Function.constFalse,
      success: () => 'Un e-mail vous a été envoyé avec la procédure à suivre pour choisir un nouveau mot de passe.',
    },
  }),
};

const ForgotPasswordPage: FC = () => {
  const [loading, send, error] = useAction(actions.forgotPassword);

  const handleSubmit = (params: Passwords.ForgotParams) => Effect.runPromiseExit(send(params));

  return (
    <AuthLayout title="Mot de passe oublié">
      <Seo title="Mot de passe oublié" />
      <ForgotPasswordForm loading={loading} error={error} onSubmit={handleSubmit} />
    </AuthLayout>
  );
};

const forgotPasswordRoute = defineRoute({
  actions,
  element: <ForgotPasswordPage />,
});

export default forgotPasswordRoute;
