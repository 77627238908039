import { Http, HttpService } from '@core/http';
import { Auth } from '@modules/auth/model';

export namespace AuthService {
  const URI = '/authenticate';

  export function authenticate(params: Auth.Params): Http.Effect {
    return HttpService.post(URI, params);
  }

  export function logout(): Http.Effect {
    return HttpService.delete(URI);
  }
}
