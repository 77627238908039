import config from '@root/config';

export namespace HttpUtils {
  export function getApiBaseURL(prefix: string): string {
    if (import.meta.env.DEV) {
      return prefix;
    } else {
      return new URL(prefix, config.VITE_API_URL).toString();
    }
  }
}
