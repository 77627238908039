import React, { FC } from 'react';

import { Helmet } from 'react-helmet-async';

import { pipe, Option } from 'effect';
import config from '@root/config';

interface SeoProps {
  title?: string;
}

const baseTitle = config.VITE_APP_TITLE;

const Seo: FC<SeoProps> = ({ title }) => {
  const metaTitle = pipe(
    Option.fromNullable(title),
    Option.match({
      onSome: t => `${baseTitle} - ${t}`,
      onNone: () => baseTitle,
    }),
  );

  return (
    <Helmet>
      <title>{metaTitle}</title>
    </Helmet>
  );
};

export default Seo;
