import React from 'react';
import { Route } from 'react-router-dom';
import { createLazyRoute } from '@core/router';

const module = () => import('./module');

const reportsRoutes = (
  <Route path="reports">
    <Route path=":type" {...createLazyRoute(module, m => m.reportRoute)} />
  </Route>
);

export default reportsRoutes;
