import { User } from '@modules/users/model';
import { z } from 'zod';
import { NonEmptyString } from '@shared/schemas';

export namespace Profile {
  export const UpdateParams = z.object({
    lastName: NonEmptyString,
    firstName: NonEmptyString,
  });

  export type UpdateParams = z.infer<typeof UpdateParams>;
}

export interface Profile {
  email: string;
  firstName: string | null;
  lastName: string | null;
  role: User.Role;
}
