import { defineLoader, useParentLoader } from '@core/router/loader';

import { defineRoute, redirect } from '@core/router';
import { QueryUtils } from '@shared/utils/queries';
import { ProfileService } from '@modules/profile/service';
import { Effect, pipe } from 'effect';
import { HttpStatusCode } from 'axios';

const profileLoader = defineLoader({
  id: 'profile-loader',
  handler: ({ request }) => {
    return pipe(
      ProfileService.getProfile(),
      Effect.matchEffect({
        onSuccess: Effect.succeed,
        onFailure: err => {
          if (HttpStatusCode.Unauthorized === err.status) {
            return redirect(`/login?${QueryUtils.stringify({ referrer: new URL(request.url).pathname })}`);
          }

          return err.throwResponse();
        },
      }),
    );
  },
});

const profileLoaderRoute = defineRoute({
  loader: profileLoader,
  shouldRevalidate: ({ currentUrl, defaultShouldRevalidate }) =>
    defaultShouldRevalidate && ['/profile', '/users/'].some(path => currentUrl.pathname.includes(path)),
});

export function useProfile() {
  return useParentLoader<typeof profileLoaderRoute.loader>('profile-loader');
}

export default profileLoaderRoute;
