import React, { FC } from 'react';

import { defineRoute, redirect } from '@core/router';
import AuthLayout from '@modules/auth/components/layout/AuthLayout';
import PasswordsForm from '@modules/passwords/components/PasswordsForm';
import Seo from '@shared/modules/seo/Seo';
import { defineLoader, useLoader } from '@core/router/loader';
import { z } from 'zod';
import { defineAction, useAction } from '@core/router/action';
import { Passwords } from '@modules/passwords/model';
import { PasswordService } from '@modules/passwords/service';
import { Effect, Function, Option, pipe } from 'effect';
import { HttpStatusCode } from 'axios';

const params = z.object({ token: Passwords.ActivationToken });

const loader = defineLoader({
  params,
  handler: ({ params }) => PasswordService.getActivationDetail(params.token),
});

const actions = {
  activate: defineAction({
    type: 'activate',
    params,
    payload: Passwords.Params,
    handler: ({ params, payload }) =>
      pipe(
        PasswordService.activateAccount(params.token, payload),
        Effect.tap(() => redirect('/')),
      ),
    flashOptions: { error: Function.constFalse },
  }),
};

const ActivationPage: FC = () => {
  const { email } = useLoader<typeof loader>();

  const [loading, activate, error] = useAction(actions.activate);

  const handleActivateAccount = ({ password }: Passwords.Body) => Effect.runPromiseExit(activate({ password }));

  const errorMessage = pipe(
    error,
    Option.map(error =>
      HttpStatusCode.Conflict === error.status ? 'Compte déjà activé.' : 'Une erreur technique est survenue.',
    ),
  );

  return (
    <AuthLayout title="Activation">
      <Seo title="Activation" />
      <PasswordsForm email={email} loading={loading} error={errorMessage} onSubmit={handleActivateAccount} />
    </AuthLayout>
  );
};

const activationRoute = defineRoute({
  loader,
  actions,
  element: <ActivationPage />,
});

export default activationRoute;
