import { CSSProperties, ReactNode } from 'react';

import isEqual from 'lodash.isequal';

import { Filter } from '@shared/modules/filter';
import { Option } from 'effect';

export class Range<T, F extends Filter = {}, S extends Filter.Sort = null> {
  constructor(
    readonly items: ReadonlyMap<number, T>,
    readonly total: number,
    readonly filter: F,
    readonly sort: S | null,
    readonly cursorSize: number,
  ) {}

  merge(newRange: Range<T, F, S>): Range<T, F, S> {
    if (isEqual(this.filter, newRange.filter)) {
      return new Range<T, F, S>(
        new Map<number, T>([...Array.from(this.items.entries()), ...Array.from(newRange.items.entries())]),
        newRange.total,
        newRange.filter,
        newRange.sort,
        newRange.cursorSize,
      );
    }

    return newRange;
  }

  has(index: number): boolean {
    return this.items.has(index);
  }

  get(index: number): Option.Option<T> {
    return Option.fromNullable(this.items.get(index));
  }

  toList(): Array<T> {
    return Array.from(this.items.values());
  }

  map<B>(fa: (a: T) => B): Range<B, F, S> {
    return new Range(
      new Map<number, B>(Array.from(this.items, ([key, value]) => [key, fa(value)])),
      this.total,
      this.filter,
      this.sort,
      this.cursorSize,
    );
  }

  static fromRangeResult<T, F extends Filter = {}, S extends Filter.Sort = null>(result: Range.Result<T, F, S>) {
    return new Range(
      new Map<number, T>(result.items.map((item, i) => [i + result.startIndex, item])),
      result.total,
      result.filter,
      result.sort,
      result.cursorSize,
    );
  }

  static fromArray<T, F extends Filter = {}, S extends Filter.Sort = null>(
    list: Array<T>,
    filter: F,
    sort: S | null = null,
  ): Range<T, F, S> {
    return new Range<T, F, S>(
      new Map<number, T>(list.map((item, i) => [i, item])),
      list.length,
      filter,
      sort,
      list.length,
    );
  }
}

export namespace Range {
  export class Cursor {
    static DEFAULT_SIZE = 50;

    constructor(
      public startIndex: number,
      public endIndex: number,
      public size: number = Cursor.DEFAULT_SIZE,
    ) {}

    static fromPage(page: number, size: number = Cursor.DEFAULT_SIZE) {
      return new Cursor((page - 1) * size, page * size - 1);
    }

    static initial(size: number = Cursor.DEFAULT_SIZE) {
      return Cursor.fromPage(1, size);
    }

    static fromIndex(index: number, size: number = Cursor.DEFAULT_SIZE) {
      const startIndex = Math.max(0, index - size / 2);

      return new Cursor(startIndex, startIndex + size - 1, size);
    }

    toPage() {
      return Math.floor(this.startIndex / this.size) + 1;
    }

    get queries() {
      return {
        startIndex: this.startIndex,
        endIndex: this.endIndex,
      };
    }
  }

  export interface Result<T, F extends Filter = {}, S extends Filter.Sort = null>
    extends Required<Omit<Cursor, 'size'>> {
    total: number;
    items: Array<T>;
    filter: F;
    sort: S | null;
    cursorSize: number;
  }

  export type NestedResult<Key extends string, T, F extends Filter = {}, S extends Filter.Sort = null> = {
    [key in Key]: Result<T, F, S>;
  };
}

export interface VirtualizedListChildrenProps<T> {
  item: T;
  index: number;
  style: CSSProperties;

  ref: (element: Element | null) => void;
}

export interface VirtualizedListProps<T> {
  header?: ReactNode;
  range: Range<T>;
  rowHeight?: number;
  loadPage: (page: number) => void;
  emptyMessage?: string;
  children: (props: VirtualizedListChildrenProps<T>) => ReactNode;
}
