import axios from 'axios';

import { v4 as uuid } from 'uuid';
import { QueryUtils } from '@shared/utils/queries';
import { Http } from '@core/http/model';

import config from '@root/config';
import { HttpUtils } from '@core/http/utils';

export const defaultAxiosInstance = axios.create({
  paramsSerializer: { serialize: QueryUtils.stringify },
  withCredentials: true,
  baseURL: HttpUtils.getApiBaseURL(config.VITE_API_PREFIX),
});

defaultAxiosInstance.interceptors.request.use(config => ({
  ...config,
  headers: config.headers.set(Http.TRANSACTION_ID_HEADER_KEY, uuid()),
}));
