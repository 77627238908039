import { z } from 'zod';
import { Email, NonEmptyString } from '@shared/schemas';

export namespace Passwords {
  export const ResetToken = z.string().brand<'ResetPasswordToken'>();
  export type ResetToken = z.infer<typeof ResetToken>;

  export const ActivationToken = z.string().brand<'ActivationToken'>();
  export type ActivationToken = z.infer<typeof ActivationToken>;

  export const ForgotParams = z.object({
    email: Email,
  });
  export type ForgotParams = z.infer<typeof ForgotParams>;

  export const Body = z
    .object({
      password: NonEmptyString,
      confirmPassword: z.string().min(1, 'Champs requis'),
    })
    .refine(value => value?.password && value.confirmPassword && value.password === value.confirmPassword, {
      path: ['confirmPassword'],
      message: 'Les mots de passe ne correspondent pas',
    });

  export type Body = z.infer<typeof Body>;

  export const Params = z.object({
    password: NonEmptyString,
  });
  export type Params = z.infer<typeof Params>;
}
