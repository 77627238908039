import z from 'zod';
import { Email, NonEmptyString } from '@shared/schemas';

export namespace User {
  export const Id = z.string().uuid().brand<'UserId'>();
  export type Id = z.infer<typeof Id>;

  export enum ActivationState {
    Pending = 'pending',
    Activated = 'activated',
  }

  export const activationStateLabel: Record<ActivationState, string> = {
    [ActivationState.Pending]: 'En attente',
    [ActivationState.Activated]: 'Actif',
  };

  export enum Role {
    Admin = 'admin',
    Consultation = 'consultation',
  }

  export const roleLabel: Record<Role, string> = {
    [Role.Admin]: 'Administrateur',
    [Role.Consultation]: 'Consultation',
  };

  export const Body = z.object({
    email: Email,
    firstName: NonEmptyString,
    lastName: NonEmptyString,
    role: z.nativeEnum(Role),
  });
  export type Body = z.infer<typeof Body>;

  export const CreateParams = Body;
  export type CreateParams = z.infer<typeof Body>;

  export const UpdateParams = Body.omit({ email: true });
  export type UpdateParams = z.infer<typeof UpdateParams>;
}

export interface User {
  id: User.Id;
  email: string;
  firstName: string | null;
  lastName: string | null;
  activationState: User.ActivationState;
  role: User.Role;
}
