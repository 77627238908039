import React, { FC } from 'react';

import { Http } from '@core/http';
import { renderOptional } from '@shared/utils/render';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { Alert, Anchor, Button, Group, Text, TextInput } from '@mantine/core';
import { Link } from 'react-router-dom';
import { Passwords } from '@modules/passwords/model';
import { Option } from 'effect';
import { HttpStatusCode } from 'axios';

interface ForgotPasswordFormProps {
  loading: boolean;
  error: Option.Option<Http.Error>;
  onSubmit: (params: Passwords.ForgotParams) => void;
}

const ForgotPasswordForm: FC<ForgotPasswordFormProps> = ({ loading, error, onSubmit }) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<Passwords.ForgotParams>({
    resolver: zodResolver(Passwords.ForgotParams),
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      {renderOptional(error, {
        onSome: error => (
          <Alert color="red" mb={10}>
            <Text color="red" size="sm" weight={600} align="center">
              {HttpStatusCode.NotFound === error.status
                ? "L'email n'est rattaché à aucun compte."
                : HttpStatusCode.Forbidden === error.status
                  ? 'Le compte ne permet pas de réinitialiser le mot de passe'
                  : 'Une erreur technique est survenue.'}
            </Text>
          </Alert>
        ),
      })}

      <Text size="sm" align="center">
        Merci de renseigner l'adresse e-mail associée à votre compte. Nous vous enverrons les instructions afin de
        modifier votre mot de passe.
      </Text>

      <TextInput
        mt="md"
        type="email"
        id="email"
        placeholder="Entrez votre adresse e-mail"
        required={!!errors.email}
        {...register('email')}
      />

      <Group position="center" mt="lg">
        <Button type="submit" loading={loading} disabled={loading}>
          Valider
        </Button>
      </Group>

      <Group position="center" mt="sm">
        <Anchor component={Link} to="/login" size="sm">
          Retour
        </Anchor>
      </Group>
    </form>
  );
};

export default ForgotPasswordForm;
