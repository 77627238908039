import Seo from '@shared/modules/seo/Seo';
import { renderOptional } from '@shared/utils/render';
import React, { FC, PropsWithChildren, ReactNode } from 'react';
import { Link, NavLink, resolvePath, To, useLocation } from 'react-router-dom';
import * as Styled from './Page.styles';
import { Anchor, Box, Breadcrumbs, Paper, Tabs, Text, Title } from '@mantine/core';
import { IconChevronRight } from '@tabler/icons-react';
import { filterEmptyStringToOption } from '@shared/utils/string';
import { pipe, Option } from 'effect';

export const PAGE_SCROLLER_ID = 'page-scroller';

export interface Tab {
  title: string;
  to: To;
  exact?: boolean;
}

export interface Breadcrumb {
  title: ReactNode;
  to?: To;
}

export interface BottomBarProps {
  left?: ReactNode;
  right?: ReactNode;
}

export interface PageProps {
  title?: ReactNode;
  seoTitle?: string;
  breadcrumbs?: Breadcrumb | Array<Breadcrumb>;
  tabs?: Array<Tab>;
  bottom?: BottomBarProps;
  noPadding?: boolean;
}

const PageTabs: FC<{ tabs?: Array<Tab> }> = ({ tabs }) => {
  const location = useLocation();

  if (tabs && tabs.length) {
    return (
      <Paper>
        <Tabs value={location.pathname}>
          <Tabs.List>
            {tabs.map((tab, i) => (
              <Tabs.Tab value={resolvePath(tab.to).pathname} key={i} sx={{ padding: 0 }}>
                <Anchor component={NavLink} to={tab.to} sx={{ display: 'block', padding: '10px 20px' }}>
                  {tab.title}
                </Anchor>
              </Tabs.Tab>
            ))}
          </Tabs.List>
        </Tabs>
      </Paper>
    );
  }

  return null;
};

const PageBottomBar: FC<{ bottom?: BottomBarProps }> = ({ bottom }) => {
  const optBottom = Option.fromNullable(bottom);

  const leftContent = pipe(
    optBottom,
    Option.flatMapNullable(b => b.left),
  );

  const rightContent = pipe(
    optBottom,
    Option.flatMapNullable(b => b.right),
  );

  if (Option.isSome(leftContent) || Option.isSome(rightContent)) {
    return (
      <Styled.PageBottom>
        <div>
          <div className="left">{Option.getOrNull(leftContent)}</div>
          <div className="right">{Option.getOrNull(rightContent)}</div>
        </div>
      </Styled.PageBottom>
    );
  }

  return null;
};

const Page: FC<PropsWithChildren<PageProps>> = ({
  title,
  seoTitle,
  breadcrumbs,
  tabs,
  bottom,
  noPadding,
  children,
}) => {
  const breadcrumbsOpts = pipe(
    Option.fromNullable(breadcrumbs),
    Option.map(breadcrumbs => (Array.isArray(breadcrumbs) ? breadcrumbs : [breadcrumbs])),
  );

  const titleOps = Option.fromNullable(title);

  const computeSeoTitle = pipe(
    filterEmptyStringToOption(seoTitle),
    Option.orElse(() => (typeof title === 'string' ? Option.some(title) : Option.none())),
    Option.getOrUndefined,
  );

  return (
    <Styled.PageContainer>
      <Seo title={computeSeoTitle} />

      {Option.isSome(titleOps) || Option.isSome(breadcrumbsOpts) ? (
        <Styled.PageTop>
          <Styled.PageTopLeft>
            {renderOptional(titleOps, {
              onSome: title => <Box my={10}>{typeof title === 'string' ? <Title>{title}</Title> : title}</Box>,
            })}

            {renderOptional(breadcrumbsOpts, {
              onSome: breadcrumbs => (
                <Breadcrumbs my={10} separator={<IconChevronRight size={15} />} sx={{ alignItems: 'center' }}>
                  {breadcrumbs.map((item, i) =>
                    item.to ? (
                      <Anchor size="sm" key={i} component={Link} color="blue" to={item.to} weight="500">
                        {item.title}
                      </Anchor>
                    ) : (
                      <Text key={i} size="sm">
                        {item.title}
                      </Text>
                    ),
                  )}
                </Breadcrumbs>
              ),
            })}
          </Styled.PageTopLeft>
        </Styled.PageTop>
      ) : null}

      <PageTabs tabs={tabs} />

      <Styled.PageContentWrapper id={PAGE_SCROLLER_ID}>
        <Styled.PageContent p={noPadding ? 0 : 20}>
          <Styled.PageChildren pb={noPadding ? 0 : 20}>{children}</Styled.PageChildren>
        </Styled.PageContent>
      </Styled.PageContentWrapper>

      <PageBottomBar bottom={bottom} />
    </Styled.PageContainer>
  );
};

export default Page;
