import { filterEmptyStringToOption } from '@shared/utils/string';
import { pipe, Option } from 'effect';

export namespace UserUtils {
  export function getUserName(email: string, firstName?: string | null, lastName?: string | null) {
    return pipe(
      Option.all({
        firstName: filterEmptyStringToOption(firstName),
        lastName: filterEmptyStringToOption(lastName),
      }),
      Option.match({
        onSome: ({ firstName, lastName }) => `${firstName} ${lastName}`,
        onNone: () => email,
      }),
    );
  }

  export function getUserInitial(email: string, firstName?: string | null, lastName?: string | null) {
    return pipe(
      Option.all({
        firstName: filterEmptyStringToOption(firstName),
        lastName: filterEmptyStringToOption(lastName),
      }),
      Option.match({
        onSome: ({ firstName, lastName }) => `${firstName.charAt(0)}${lastName.charAt(0)}`,
        onNone: () => email.charAt(0),
      }),
    ).toUpperCase();
  }
}
