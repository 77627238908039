import React, { FC } from 'react';
import { defineRoute, redirect } from '@core/router';
import AuthLayout from '@modules/auth/components/layout/AuthLayout';
import PasswordsForm from '@modules/passwords/components/PasswordsForm';
import Seo from '@shared/modules/seo/Seo';
import { defineLoader, useLoader } from '@core/router/loader';
import { z } from 'zod';
import { defineAction, useAction } from '@core/router/action';
import { Passwords } from '@modules/passwords/model';
import { PasswordService } from '@modules/passwords/service';
import { Effect, Function, Option, pipe } from 'effect';

const params = z.object({ token: Passwords.ResetToken });

const loader = defineLoader({
  params,
  handler: ({ params }) => PasswordService.getPasswordResetDetail(params.token),
});

const actions = {
  reset: defineAction({
    type: 'reset',
    params,
    payload: Passwords.Params,
    handler: ({ params, payload }) =>
      pipe(
        PasswordService.resetPassword(params.token, payload),
        Effect.tap(() => redirect('/login')),
      ),
    flashOptions: {
      error: Function.constFalse,
      success: () => 'Mot de passe modifié avec succès.',
    },
  }),
};

const ResetPasswordPage: FC = () => {
  const { email } = useLoader<typeof loader>();

  const [loading, send, error] = useAction(actions.reset);

  const handleResetPassword = ({ password }: Passwords.Body) => Effect.runPromiseExit(send({ password }));

  const errorMessage = pipe(
    error,
    Option.map(() => 'Une erreur technique est survenue.'),
  );

  return (
    <AuthLayout title="Changement de mot de passe">
      <Seo title="Mot de passe oublié" />
      <PasswordsForm email={email} loading={loading} error={errorMessage} onSubmit={handleResetPassword} />
    </AuthLayout>
  );
};

const resetPasswordRoute = defineRoute({
  loader,
  actions,
  element: <ResetPasswordPage />,
});

export default resetPasswordRoute;
