import React, { FC, useEffect } from 'react';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Outlet,
  Route,
  To,
  useNavigation,
} from 'react-router-dom';

import { createRoute } from '@core/router';
import Layout from '@layout/Layout';

import { resetNavigationProgress, startNavigationProgress } from '@mantine/nprogress';
import ErrorPage from '@shared/components/error-page/ErrorPage';
import profilePageRoute from '@modules/profile/ProfilePage';

import usersRoutes from '@modules/users/routes';
import profileLoaderRoute, { useProfile } from '@modules/profile/loader';
import reportsRoutes from '@modules/reports/routes';
import forgotPasswordRoute from '@modules/passwords/ForgotPasswordPage';
import resetPasswordRoute from '@modules/passwords/ResetPasswordPage';
import activationRoute from '@modules/passwords/ActivationPage';
import loginRoute from '@modules/auth/LoginPage';
import logoutRoute from '@modules/auth/logout';
import { Report } from '@modules/reports/model';
import { User } from '@modules/users/model';

const NavigationLoader: FC = () => {
  const navigation = useNavigation();

  useEffect(() => {
    if (navigation.state !== 'idle') {
      startNavigationProgress();
    } else {
      resetNavigationProgress();
    }
  }, [navigation.state]);

  return <Outlet />;
};

const indexRouteByRole: Record<User.Role, To> = {
  [User.Role.Admin]: '/users',
  [User.Role.Consultation]: `/reports/${Report.Type.Water}`,
};

const IndexRoute: FC = () => {
  const profile = useProfile();

  return <Navigate to={indexRouteByRole[profile.role]} replace />;
};

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<NavigationLoader />}>
      <Route path="login" {...createRoute(loginRoute)} />

      <Route path="password-reset/forgot" {...createRoute(forgotPasswordRoute)} />
      <Route path="password-reset/:token" {...createRoute(resetPasswordRoute)} />

      <Route path="activation/:token" {...createRoute(activationRoute)} />

      <Route {...createRoute(profileLoaderRoute)}>
        <Route element={<Layout />}>
          <Route index element={<IndexRoute />} />

          <Route path="profile" {...createRoute(profilePageRoute)} />

          {reportsRoutes}
          {usersRoutes}

          <Route path="logout" {...createRoute(logoutRoute)} />

          <Route path="*" element={<ErrorPage />} />
        </Route>

        <Route path="*" element={<ErrorPage />} />
      </Route>
    </Route>,
  ),
);

export default router;
