import { Http, HttpService } from '@core/http';
import { Passwords } from '@modules/passwords/model';

export namespace PasswordService {
  const PASSWORD_URI = '/password-reset';
  const ACTIVATION_URI = '/activation';

  export function forgotPassword(params: Passwords.ForgotParams): Http.Effect {
    return HttpService.post(`${PASSWORD_URI}/forgot`, params);
  }

  export function getPasswordResetDetail(token: Passwords.ResetToken): Http.Effect<Passwords.ForgotParams> {
    return HttpService.get(`${PASSWORD_URI}/${token}`);
  }

  export function resetPassword(token: Passwords.ResetToken, params: Passwords.Params): Http.Effect {
    return HttpService.post(`${PASSWORD_URI}/${token}`, params);
  }

  export function getActivationDetail(token: Passwords.ActivationToken): Http.Effect<Passwords.ForgotParams> {
    return HttpService.get(`${ACTIVATION_URI}/${token}`);
  }

  export function activateAccount(token: Passwords.ActivationToken, params: Passwords.Params): Http.Effect {
    return HttpService.post(`${ACTIVATION_URI}/${token}`, params);
  }
}
