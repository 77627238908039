import styled from '@emotion/styled';

import { createPolymorphicComponent, Paper, PaperProps } from '@mantine/core';

export const AuthLayoutContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #fff;
  height: 100%;
  padding: 20px;

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    clip-path: polygon(0 0, 0% 100%, 100% 100%);
    background: ${props => props.theme.colors.blue[0]};
  }
`;

export const AuthLayoutLogoContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex: 1 1 auto;
  padding-bottom: 30px;
  z-index: 1;
`;

export const AuthLayoutContent = createPolymorphicComponent<'div', PaperProps>(styled(Paper)`
  z-index: 1;
  padding: 35px 70px;
  width: 100%;
  max-width: 500px;

  @media (max-width: ${props => props.theme.breakpoints.sm}px) {
    padding: 35px;
  }
`);
