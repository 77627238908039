import React, { FC } from 'react';
import { useEnhancedFormContext } from '@shared/modules/form';
import { Paper, SimpleGrid, TextInput } from '@mantine/core';
import { Profile } from '@modules/profile/model';

interface UpdateProfileFormProps {
  email: string;
}

const UpdateProfileForm: FC<UpdateProfileFormProps> = ({ email }) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useEnhancedFormContext<Profile.UpdateParams>();

  return (
    <form onSubmit={handleSubmit} noValidate>
      <Paper p="xl">
        <TextInput type="email" label="E-mail" required disabled value={email} />

        <SimpleGrid mt="md" cols={2}>
          <TextInput
            id="lastName"
            label="Nom"
            placeholder="Nom"
            required
            error={!!errors.lastName}
            {...register('lastName')}
          />

          <TextInput
            id="firstName"
            label="Prénom"
            placeholder="Prénom"
            error={!!errors.firstName}
            required
            {...register('firstName')}
          />
        </SimpleGrid>
      </Paper>
    </form>
  );
};

export default UpdateProfileForm;
