export namespace Report {
  export enum Type {
    Water = 'water',
    Input = 'input',
    GolfActivity = 'golf-activity',
  }

  export const pageTitle: Record<Type, string> = {
    [Type.Water]: 'Rapport Eau',
    [Type.Input]: 'Rapport Intrant',
    [Type.GolfActivity]: "Suivi d'activité",
  };
}
