import { ReadonlyArray, Predicate, Function } from 'effect';
import { User } from '@modules/users/model';
import { Profile } from '@modules/profile/model';

export namespace ProfilePredicates {
  export function hasRole(...roles: ReadonlyArray.NonEmptyArray<User.Role>): Predicate.Predicate<Profile> {
    return profile => roles.includes(profile.role);
  }

  export const isAdmin: Predicate.Predicate<Profile> = profile => profile.role === User.Role.Admin;
  export const anyRole: Predicate.Predicate<Profile> = Function.constTrue;
}
