import React, { FC } from 'react';

import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import { Http } from '@core/http';
import { Auth } from '@modules/auth/model';
import { renderOptional } from '@shared/utils/render';
import { Alert, Anchor, Button, Group, PasswordInput, Text, TextInput } from '@mantine/core';
import { Option } from 'effect';
import { HttpStatusCode } from 'axios';

interface LoginFormProps {
  loading: boolean;
  error: Option.Option<Http.Error>;
  onSubmit: (params: Auth.Params) => void;
}

const LoginForm: FC<LoginFormProps> = ({ loading, error, onSubmit }) => {
  const {
    formState: { errors },
    handleSubmit,
    register,
  } = useForm<Auth.Params>({
    resolver: zodResolver(Auth.Params),
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      {renderOptional(error, {
        onSome: error => (
          <Alert color="red" mb={10}>
            <Text color="red" size="sm" weight={600} align="center">
              {HttpStatusCode.Unauthorized === error.status
                ? 'Email ou mot de passe incorrect.'
                : 'Une erreur technique est survenue.'}
            </Text>
          </Alert>
        ),
      })}

      <TextInput
        id="email"
        type="email"
        label="E-mail"
        required
        placeholder="Saisir votre e-mail"
        error={!!errors.email}
        {...register('email')}
      />

      <PasswordInput
        mt="sm"
        id="password"
        label="Mot de passe"
        required
        placeholder="Saisir votre mot de passe"
        error={!!errors.password}
        {...register('password')}
      />

      <Group mt="md" position="center">
        <Button type="submit" loading={loading} disabled={loading}>
          Se connecter
        </Button>
      </Group>

      <Group mt="md" position="center">
        <Anchor component={Link} color="blue" underline to="/password-reset/forgot" size="sm" weight="600">
          Mot de passe oublié ?
        </Anchor>
      </Group>
    </form>
  );
};

export default LoginForm;
