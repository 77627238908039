import { z } from 'zod';
import { Email, NonEmptyString } from '@shared/schemas';

export namespace Auth {
  export const Params = z.object({
    email: Email,
    password: NonEmptyString,
  });
  export type Params = z.infer<typeof Params>;
}
