import React, { FC, useMemo } from 'react';
import { defineRoute } from '@core/router';
import Page, { PageProps } from '@layout/page/Page';
import { EnhancedForm, useEnhancedForm } from '@shared/modules/form';
import { zodResolver } from '@hookform/resolvers/zod';
import UpdateProfileForm from '@modules/profile/components/UpdateProfileForm';
import { SharedButton } from '@styles/shared';
import { defineAction, useAction } from '@core/router/action';
import { useProfile } from '@modules/profile/loader';
import { Profile } from '@modules/profile/model';
import { ProfileService } from '@modules/profile/service';

const actions = {
  update: defineAction({
    type: 'update',
    payload: Profile.UpdateParams,
    handler: ({ payload }) => ProfileService.updateProfile(payload),
    flashOptions: { success: () => 'Compte modifié avec succès' },
  }),
};

const ProfilePage: FC = () => {
  const profile = useProfile();

  const { formRef, form, handleFormSubmit } = useEnhancedForm<Profile.UpdateParams>({
    resolver: zodResolver(Profile.UpdateParams),
    defaultValues: useMemo<Profile.UpdateParams>(
      () => ({
        lastName: profile.lastName ?? '',
        firstName: profile.firstName ?? '',
      }),
      [profile.firstName, profile.lastName],
    ),
  });

  const [loading, updateProfile] = useAction(actions.update);

  const pageProps: PageProps = {
    title: 'Mon compte',
    bottom: {
      right: <SharedButton btnType="save" loading={loading} onClick={handleFormSubmit} />,
    },
  };

  return (
    <Page {...pageProps}>
      <EnhancedForm ref={formRef} form={form} onSubmit={updateProfile} preventLeave>
        <UpdateProfileForm email={profile.email} />
      </EnhancedForm>
    </Page>
  );
};

const profilePageRoute = defineRoute({
  actions,
  element: <ProfilePage />,
});

export default profilePageRoute;
