import { MantineProvider } from '@mantine/core';
import React, { FC, PropsWithChildren } from 'react';
import { MantineThemeOverride } from '@mantine/core';
import { Notifications } from '@mantine/notifications';

const theme: MantineThemeOverride = {
  fontFamily: 'Open Sans, sans-serif',
  colors: {
    blue: [
      '#ebf4ff',
      '#c1d5ec',
      '#a7b8dc',
      '#004c99',
      '#133c6d',
      '#103561',
      '#0d2849',
      '#103561',
      '#0a1e37',
      '#003361',
    ],
    green: [
      '#daf2e1',
      '#b5e4c4',
      '#b2f2bb',
      '#45bc6b',
      '#339e37',
      '#1b9140',
      '#188038',
      '#115d29',
      '#0e4b22',
      '#0b3a1a',
    ],
  },
  primaryColor: 'green',
  primaryShade: 4,
  headings: {
    sizes: {
      h1: {
        fontSize: '1.25rem',
      },
    },
  },
  components: {
    Input: {
      defaultProps: {
        radius: 'md',
      },
    },
    InputWrapper: {
      styles: {
        label: {
          fontWeight: 600,
          paddingBottom: 5,
        },
      },
    },
    Paper: {
      defaultProps: {
        withBorder: true,
        radius: 16,
      },
    },
    Badge: {
      styles: {
        root: {
          textTransform: 'unset',
        },
      },
    },
    Modal: {
      styles: {
        overlay: {
          zIndex: 20000,
        },
        inner: {
          zIndex: 20000,
        },
      },
      defaultProps: theme => ({
        overlayProps: {
          color: theme.colors.gray[2],
          blur: 2,
        },
      }),
    },
  },
};
const MantineTheme: FC<PropsWithChildren> = ({ children }) => {
  return (
    <MantineProvider theme={theme} withNormalizeCSS withGlobalStyles>
      <Notifications position="top-right" />
      {children}
    </MantineProvider>
  );
};

export default MantineTheme;
