import { Stack } from '@mantine/core';
import styled from '@emotion/styled';

const MIN_SCREEN_WIDTH = 800;

export const LayoutContainer = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 270px 1fr;
  height: 100%;

  @media screen and (max-width: ${MIN_SCREEN_WIDTH}px) {
    display: none;
  }
`;

export const LayoutContent = styled.div`
  position: relative;
  flex: 1 1 auto;
  overflow: hidden;
`;

export const LayoutLock = styled(Stack)`
  display: none;
  height: 100%;
  text-align: center;
  justify-content: center;

  @media screen and (max-width: ${MIN_SCREEN_WIDTH}px) {
    display: flex;
  }
`;
