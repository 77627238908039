import { Http, HttpService } from '@core/http';
import { Profile } from '@modules/profile/model';

export namespace ProfileService {
  const URI = '/profile';

  export function getProfile(): Http.Effect<Profile> {
    return HttpService.get<Profile>(URI);
  }

  export function updateProfile(profile: Profile.UpdateParams): Http.Effect {
    return HttpService.post(URI, profile);
  }
}
