import React, { FC, useEffect, useMemo } from 'react';
import { Http } from '@core/http';

import * as Styled from './ErrorPage.styles';
import { isRouteErrorResponse, Link, useRouteError } from 'react-router-dom';
import { Button, Text, Title } from '@mantine/core';
import * as Sentry from '@sentry/react';
import { HttpStatusCode } from 'axios';

interface ErrorPageProps {
  error?: Http.Error;
}

const ErrorPage: FC<ErrorPageProps> = ({ error = Http.Error.notFound }) => {
  const internalError = useRouteError();

  useEffect(() => {
    if (internalError) {
      console.error(internalError);

      if (isRouteErrorResponse(internalError) && internalError.status !== 404) {
        Sentry.captureException(internalError, {
          level: 'error',
        });
      } else {
        Sentry.captureException(internalError, {
          level: 'error',
        });
      }
    }
  }, [internalError]);

  const title = useMemo(() => {
    const getTitleFromStatus = (status: number) => {
      switch (status) {
        case HttpStatusCode.NotFound:
          return 'Page introuvable';
        case HttpStatusCode.Forbidden:
          return 'Accès refusé';
        default:
          return `Une erreur ${status} est survenue`;
      }
    };

    if (internalError) {
      if (isRouteErrorResponse(internalError)) {
        return getTitleFromStatus(internalError.status);
      }

      return 'Erreur technique interne';
    }

    return getTitleFromStatus(error.status);
  }, [error.status, internalError]);

  return (
    <Styled.ErrorPageContainer>
      <Title>{title}</Title>

      <Text p="30px 0">Nous vous invitons à revenir à l'accueil</Text>

      <Button component={Link} to="/">
        Revenir à l'accueil
      </Button>
    </Styled.ErrorPageContainer>
  );
};

export default ErrorPage;
